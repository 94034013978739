import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { RoamButtonComponent } from "../../button/roam-button/roam-button.component";

@Component({
  standalone: true,
  imports:[MatDialogModule, RoamButtonComponent],
  selector: 'app-roam-dialog-type-two',
  templateUrl: './roam-dialog-type-two.component.html',
  styles:[
      `
      .mat-dialog-content-two {
        padding: 0 24px 24px;
      }
    `
  ]
})
export class RoamDialogTypeTwoComponent {

  @Input()
  public title!: string;

  @Input()
  public isLoading!: boolean;

  @Input()
  public btnText: string = 'Save'

  @Output()
  public onSave: EventEmitter<Event> = new EventEmitter<Event>();
  

}
